<template>
  <div class="step">
    <div class="st-1 st" :class="s === 1 || s === 2 || s === 3 ? 'active' : ''">
      <span>1</span>
      <span>创建模版</span>
    </div>

    <div class="divide" :class="s === 2 || s === 3 ? 'active' : ''">
      <span></span>
    </div>

    <div class="st-2 st" :class="s === 2 || s === 3 ? 'active' : ''">
      <span>2</span>
      <span>设置签署方式</span>
    </div>

    <div class="divide"  :class="s === 3 ? 'active' : ''">
      <span></span>
    </div>

    <div class="st-3 st" :class="s === 3 ? 'active' : ''">
      <span>3</span>
      <span>选择用章</span>
    </div>

  </div>
</template>

<script>
export default {
  name: 'step',
  props: [
    's'
  ],
  computed: {

  },
  methods: {

  }

}

</script>


<style scoped lang="less">
@import "../../../styles/common.less";
.step{
  padding: 20px;
  #flex;
  #flex-vc;
  .st{
    #flex;
    #flex-hc;
    #flex-vc;
    width: 20%;
    span:nth-of-type(1){
      #tac;
      line-height: 30px;
      color: @color-white;
      background-color: @color-gray;
      width: 30px;
      height: 30px;
      font-size: 18px;
      #border-radius(50%);
    }
    span:nth-of-type(2){
      padding: 0 5px;
      font-size: 16px;
      color: @color-lightblack;
    }
  }
  .divide{
    width: 20%;
    #flex-vc;
    #flex-hc;
    span{
      #flex;
      background-color: @color-border-gray;
      height: 2px;
    }
  }
  .active {
    span:nth-of-type(1){
      #tac;
      background-color: @color-blue;
    }
    span:nth-of-type(2){
      color: @color-black;
      #fw;
    }
  }

}

</style>