<template>
  <a-spin :spinning="spinning">
    <div class="choose-seal">
      <div class="t-w">
        <step :s="3" />
      </div>

      <div class="sel-w">
        <div class="item-w">
          <div class="iw-l">选择项目关联:</div>
          <div class="iw-r">
            <a-input
              v-model="projectName"
              placeholder="选择项目关联"
              @click="selectProjectModal"
            />
            <a-input v-model="submitParams.projectId" type="hidden" />
          </div>
        </div>
        <!-- <div class="item-w" v-if="standardType === '0'">
          <div class="iw-l">选择项目关联:</div>
          <div class="iw-r">
            <a-select
              size="default"
              style="width: 100%"
              @change="proChange"
              v-model="submitParams.projectId"
              placeholder="请选择"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in projectOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </div>
        </div> -->
      </div>

      <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :actions-type="actionsType"
        :hide-row-selection="true"
        :hide-pagination="true"
        :columns="columns"
        :min-height="100"
        :scroll="{ x: 0 }"
        :current-page="pageNumber"
      >
      </react-table>

      <div class="b-w">
        <a-button size="default" @click="goBack" style="margin-right: 10px">{{
          l("上一步")
        }}</a-button>
        <a-button size="default" type="primary" @click="_saveAgreement">
          {{ l("保存") }}
        </a-button>
      </div>

      <a-modal
        centered
        :title="inputModalParams.title"
        :visible="inputModalParams.visible"
        :confirm-loading="inputModalParams.confirmLoading"
        :cancelText="inputModalParams.cancelText"
        :okText="inputModalParams.okText"
        :maskClosable="inputModalParams.maskClosable"
        :destroyOnClose="inputModalParams.destroyOnClose"
        :width="inputModalParams.width"
        @ok="inputModalParams.confirm"
        @cancel="inputModalParams.cancel"
      >
        <a-form :form="form" v-bind="formItemLayout">
          <a-form-item
            label="客户印章"
            v-if="inputModalParams.type === 'Customer'"
          >
            <a-select
              @change="modalSealChange"
              v-decorator="[
                'customerSealId',
                { rules: [{ required: true, message: '请选择客户印章' }] },
              ]"
              placeholder="请选择"
            >
              <a-select-option
                v-for="(item, index) in cusSealOption"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="签约公司印章"
            v-if="inputModalParams.type === 'Organization'"
          >
            <a-select
              @change="modalSealChange"
              v-decorator="[
                'organizationSealId',
                { rules: [{ required: true, message: '请选择客户印章' }] },
              ]"
              placeholder="请选择"
            >
              <a-select-option
                v-for="(item, index) in orgSealOption"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import Step from "./step";
import ReactTable from "@/components/react-table";
import SelectPageList from "@/components/linggong/select-page-list";

export default {
  mixins: [AppComponentBase],
  data() {
    return {
      spinning: false,
      commonService: null,
      tableData: [],
      totalItems: 0,
      columns: [],
      actionsType: {},
      submitParams: {
        agreementTemplateId: "",
        organizationUnitId: "",
        customerId: "",
        projectId: "",
        signMethodInfos: [],
      },
      smInfo: [],
      projectName: "",
      orgName: "",
      proName: "",
      sealName: "",
      projectOption: [],
      sealOption: [],
      cusSealOption: [],
      orgSealOption: [],
      standardType: null,
      // modal
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      inputModalParams: {
        type: "",
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "请选择印章",
        cancelText: "取消",
        okText: "确认",
        form: {},
        id: null,
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
    };
  },
  computed: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.standardType = this.$route.params.standard;
    this._agreementSigningMethodGetList();
    this.initColumns();
  },
  methods: {
    initColumns() {
      let _this = this;
      this.columns = [
        {
          title: this.l("签署类型"),
          dataIndex: "signTypeName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signTypeName" },
        },
        {
          title: this.l("名称"),
          dataIndex: "agreementTemplateName",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            if (
              _this.smInfo[index].signerType === "Organization" ||
              _this.smInfo[index].signerType === "Customer"
            ) {
              return _this.orgName ? _this.orgName : "-";
            } else {
              return "-";
            }
          },
          scopedSlots: { customRender: "agreementTemplateName" },
        },
        {
          title: this.l("印章"),
          dataIndex: "isAutoSignDesc",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            if (_this.smInfo[index].signerType === "Organization") {
              let child = _this.$createElement("a", {
                domProps: {
                  innerHTML:
                    _this.smInfo && _this.smInfo[index].organizationSealId
                      ? _this.smInfo[index].organizationSealName
                      : "选择印章",
                },
                on: {
                  click: function () {
                    if (!_this.submitParams.projectId) {
                      _this.$notification["warning"]({
                        message: "提示",
                        description: "请选择项目关联",
                      });
                    } else {
                      _this.selectSeal(record, index, "Organization");
                    }
                  },
                },
                style: {},
                attrs: {},
              });
              let obj = {
                children: child,
                attrs: {},
                on: {},
              };
              return obj;
            } else if (_this.smInfo[index].signerType === "Customer") {
              let child = _this.$createElement("a", {
                domProps: {
                  innerHTML:
                    _this.smInfo && _this.smInfo[index].customerSealId
                      ? _this.smInfo[index].customerSealName
                      : "选择印章",
                },
                on: {
                  click: function () {
                    if (!_this.submitParams.projectId) {
                      _this.$notification["warning"]({
                        message: "提示",
                        description: "请选择项目关联",
                      });
                    } else {
                      _this.selectSeal(record, index, "Customer");
                    }
                  },
                },
                style: {},
                attrs: {},
              });
              let obj = {
                children: child,
                attrs: {},
                on: {},
              };
              return obj;
            } else {
              return "-";
            }
          },
          scopedSlots: { customRender: "isAutoSignDesc" },
        },
      ];
    },
    selectSeal(item, index, type) {
      let _this = this;
      // console.log(item, index)
      this.inputModalParams.type = type;
      this.inputModalParams.title =
        type === "Organization" ? "签约公司印章" : "客户印章";
      this._getOrgSealOption(this.submitParams.organizationUnitId);
      this.inputModalParams.visible = true;
      this.inputModalParams.confirm = function () {
        _this.form.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            if (type === "Organization") {
              _this.smInfo[
                index
              ].organizationSealName = _this.orgSealOption.filter(
                (v) => v.value === values.organizationSealId
              )[0].label;
              _this.smInfo[index].organizationSealId =
                values.organizationSealId;
            } else {
              _this.smInfo[index].customerSealName = _this.cusSealOption.filter(
                (v) => v.value === values.customerSealId
              )[0].label;
              _this.smInfo[index].customerSealId = values.customerSealId;
            }
            _this.initColumns();
            _this.inputModalParams.visible = false;
          }
        });
      };
    },
    proChange(value) {
      console.log(value);
      this.submitParams.projectId = value;
    },
    modalSealChange(value) {
      console.log(value);
    },
    async _getContractInfoById(projectId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Project/GetContractInfoById",
          params: { projectId: projectId },
        });
        this.submitParams.organizationUnitId = res.organizationUnitId;
        this.submitParams.customerId = res.customerId;
        this.orgName = res.orgName;

        this.initColumns();

        this._getCustomerSealOption(res.customerId);
        this._getOrganizationrSealOption(res.organizationUnitId);

        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getOrganizationrSealOption(organizationUnitId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Organization/GetOrganizationrSealOption",
          params: { organizationUnitId: organizationUnitId },
        });
        this.orgSealOption = res;
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getCustomerSealOption(customerId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Customer/GetCustomerSealOption",
          params: { customerId: customerId },
        });
        this.cusSealOption = res;
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },

    async _getOrgSealOption(organizationUnitId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Organization/GetOrganizationrSealOption",
          params: { organizationUnitId: organizationUnitId },
        });
        this.sealOption = res;
        this.inputModalParams.sealOption = res;
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getProSelectList(orgId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProject/GetSelectList",
          params: { orgId: orgId },
        });
        this.projectOption = res.map((item) => {
          return {
            ...item,
            label: item.text,
          };
        });
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _agreementSigningMethodGetList() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/AgreementSigningMethod/GetList",
          params: { agreementTemplateId: this.$route.params.id },
        });
        this.tableData = res.map((item) => {
          return {
            ...item,
            signTypeName:
              item.signerType === "Organization"
                ? "组织架构"
                : item.signerType === "Customer"
                ? "客户"
                : "个人",
          };
        });
        this.smInfo = res.map((item) => {
          return {
            signerType: item.signerType,
            // organizationName: null,
            organizationSealId: null,
            organizationSealName: null,
            customerSealId: null,
            customerSealName: null,
            isAutoSign: item.isAutoSign,
            signMethod: item.signMethod,
            sort: item.sort,
          };
        });
        this.totalItems = res.length;
        this.initSignerTypeList(this.tableData);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _saveAgreement() {
      // let smInfo = this.smInfo.filter(v => !v.organizationSealId && v.signerType === 'Organization')
      // if (smInfo.length) {
      //   this.$notification['warning']({
      //     message: '提示',
      //     description: `请选择印章`,
      //   })
      //   return
      // }
      this.spinning = true;
      this.submitParams.signMethodInfos = this.smInfo;
      this.submitParams.agreementTemplateId = this.$route.params.id;
      const opts = { ...this.submitParams };
      try {
        // console.log({...this.submitParams})
        let res = await this.commonService.post({
          url: "/api/services/app/ProjectAgreement/SaveAgreement",
          params: { ...opts },
        });
        this.spinning = false;
        this.$notification["success"]({
          message: "",
          description: `保存成功`,
        });
        setTimeout(() => {
          this.$router.push({
            path: `/app/linggong/templeteManage`,
          });
        }, 1000);
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          //   selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          this.submitParams.projectId = data.id;
          this.projectName = data.name;
          this._getContractInfoById(data.id);
        }
      });
    },
    goBack() {
      this.$router.back();
    },
  },
  components: {
    ReactTable,
    Step,
  },
};
</script>


<style scoped lang="less">
@import "../../../styles/common.less";
.choose-seal {
  padding: 50px 100px;
  .t-w {
    background-color: @color-white;
  }
  .sel-w {
    background-color: @color-white;
    // width: 400px;
    .item-w {
      padding: 10px 0;
      #flex;
      #flex-vc;
      margin: 0 auto;
      width: 400px;
      .iw-l {
        #tac;
        padding-right: 5px;
        width: 30%;
        // font-size: 12px;
      }
      .iw-r {
        width: 70%;
      }
    }
  }

  .b-w {
    padding-top: 60px;
    #flex;
    #flex-hc;
    background-color: @color-white;
  }
}
</style>